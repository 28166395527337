html {
  --primary-width: calc(min(100vw, 500px));
  --top-margin: 3ex;
}

body.eval {
    overflow: hidden;
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100vw;
    max-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.main {
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  user-select: none;
  cursor: grab;
}

.skipbox {
  position: absolute;
  width: 150px;
  height: 50px;
  background-color: #dddddd;
  opacity: 0.5;
  left: calc(var(--primary-width) / 2 - 75px);
  top: 0;
  z-index: 1;
}

.fullscreenbutton {
  position: absolute;
  top: 0;
  left: calc(50% + var(--primary-width) / 2 - 45px);
  z-index: 1;
  padding: 0;
  margin: 0;
}

.undobutton {
  position: absolute;
  top: 0;
  left: calc(45% + 3em);
  z-index: 1;
  padding: 0;
  margin: 0;
}

.skipbutton {
  position: absolute;
  top: 0;
  left: calc(45% - 2em);
  z-index: 1;
  padding: 0;
  margin: 0;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Mui-disabled {
  opacity: 25% !important;
}

.gdprinfo {
  font-size: 16pt;
}

ul.languagelist {
  position: absolute;
  top: 0;
  left: calc(50% - var(--primary-width) / 2);
  z-index: 1;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
}

ul.languagelist li:not(:first-child) {
  padding-left: 1ch;
}

ul.languagelist li:not(:first-child)::before {
  content: '|';
  margin-right: 1ch;
}

ul.languagelist span.selectedlanguage {
  font-weight: bold;
}

ul.languagelist a.unselectedlanguage {
  color: blue;
  cursor: pointer;
}
